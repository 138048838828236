import { APP_NAME, MULPASS_URL } from '@shopify/const'
import Cookies from 'js-cookie'
import { stringify } from 'query-string'

// 如果是美洲国家
export function numberFormat(num: number = 0) {
  if (typeof Intl === 'undefined') {
    return num
  }

  return new Intl.NumberFormat().format(num)
}

// 如果是欧盟国家
export function DEnumberFormat(num: number = 0) {
  if (typeof Intl === 'undefined') {
    return num
  }

  let str = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num)
  //去掉结尾的欧元符号，只返回数字
  return str.substring(0, str.length - 1)
}

export function getApp(locale: string = '') {
  return APP_NAME[locale || '']
}

export function getAlpcPath(locale = '') {
  let isEU = false
  const alpcEUCookie = Cookies.get('alpcEU')
  if (alpcEUCookie === undefined || alpcEUCookie === '') {
    if (['de', 'eu-de', 'eu', 'eu-en', 'uk', 'fr', 'fra'].includes(locale)) {
      isEU = true
    }
  } else {
    let alpcEU = Number(alpcEUCookie)
    if (alpcEU) {
      isEU = true
    }
  }

  if (isEU) {
    return '/api/multipass/alpc-eu'
  }

  return '/api/multipass/alpc'
}

export function getSignInUrl(locale: string = '') {
  return (
    MULPASS_URL +
    '?' +
    stringify({
      app: getApp(locale),
      redirect: location.href,
    })
  )
}

export function getSignUpUrl(locale: string = '') {
  return (
    MULPASS_URL +
    '?' +
    stringify({
      app: getApp(locale),
      redirect: location.href,
      tab: 'register',
    })
  )
}

export function getProfileUrl(locale: string = '') {
  return (
    MULPASS_URL +
    '/account/profile?' +
    stringify({
      app: getApp(locale),
      redirect: location.href,
    })
  )
}
