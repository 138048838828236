/* eslint-disable no-unused-vars */
import useSWR, { SWRConfiguration } from 'swr'
import { getAlpcPath } from '../utils'
import { useRouter } from 'next/router'
import { useProfile } from '../ProfileContext'
import { useCallback, useRef, useState } from 'react'
import Cookies from 'js-cookie'

interface UseAlpcFetchOptionsSwrOptions<TData, Error>
  extends SWRConfiguration<TData, Error> {
  enable?: boolean
}

interface FetchOptions extends RequestInit {
  queryKey: [string, Record<string, any>]
  data?: Record<string, any>
}

interface MutationOptions extends RequestInit {
  url: string
  initData: Record<string, any>
}

interface FetcherOptions {
  url: string
  locale: string
  fetchOptions: RequestInit
  onReAuth: () => Promise<boolean>
  onUnAuth: () => void
}

let reAuthPromise: Promise<boolean> | undefined

type Fetcher<TData, TBody> = (
  url: string,
  data: { arg: TBody }
) => Promise<TData>

const fetcher = (options: FetcherOptions) => {
  return fetch(
    getAlpcPath(options.locale) + options.url,
    options.fetchOptions
  ).then(async (response) => {
    if (response.status < 300) {
      const responseData = await response.json()
      return responseData.data
    }

    // 错误处理
    if (response.status === 401) {
      // 重新登录尝试
      if (options.onReAuth) {
        if (!reAuthPromise) {
          reAuthPromise = options.onReAuth()
        }

        const result = await reAuthPromise

        reAuthPromise = undefined

        if (result) {
          // 跨区重新登录后需要替换区域标志，所以不能用最开始请求的那个链接
          return fetch(
            getAlpcPath(options.locale) + options.url,
            options.fetchOptions
          ).then(async (response) => {
            if (response.status < 300) {
              const responseData = await response.json()
              return responseData.data
            }

            if (response.status === 401) {
              if (options.onUnAuth) {
                options.onUnAuth()
              }
              return
            }
          })
        } else {
          if (options.onUnAuth) {
            options.onUnAuth()
          }
          return
        }
      }
    }
  })
}

const reAuth = async (locale: string, retry: boolean) => {
  let reloginResponse = await fetch(`${getAlpcPath(locale)}/cloud/login`, {
    method: 'POST',
  })
  let reloginResponseData = await reloginResponse.json()

  if (reloginResponse!.status < 300 && !retry) {
    if (reloginResponseData?.data?.code === 27004) {
      // 跨区登录错误
      const alpcEUCookie = Cookies.get('alpcEU')

      if (Number(alpcEUCookie) === 1) {
        Cookies.set('alpcEU', '0', {
          expires: 30,
          domain: '.anker.com',
        })
      } else {
        Cookies.set('alpcEU', '1', {
          expires: 30,
          domain: '.anker.com',
        })
      }

      // 重新尝试换区登录
      reloginResponse = await fetch(`${getAlpcPath(locale)}/cloud/login`, {
        method: 'POST',
      })
      reloginResponseData = await reloginResponse.json()

      if (reloginResponse.status > 300) {
        return false
      }
    }
  }
  if (!reloginResponseData?.data?.code) {
    // 重新登录成功
    return true
  }

  return false
}

export interface UseMutationConfig<TData> {
  onSuccess?: (response: TData) => any
  onError?: (error: Error) => any
}

const useMutation = <TData, TBody>(
  url: string,
  fetcher: Fetcher<TData, TBody>,
  mutationConfig: UseMutationConfig<TData> = {}
) => {
  const innerMutating = useRef<boolean>(false)

  const [data, setData] = useState<TData>()
  const [isMutating, setIsMutating] = useState(false)

  const urlRef = useRef(url)
  const fetcherRef = useRef(fetcher)
  const configRef = useRef(mutationConfig)

  const trigger = useCallback(
    async (fetchData: TBody, opts: UseMutationConfig<TData> = {}) => {
      if (innerMutating.current) {
        return
      }

      innerMutating.current = true
      setIsMutating(true)

      const config = {
        ...configRef.current,
        ...opts,
      }

      const { onSuccess, onError } = config || {}

      try {
        const fetchResult = await fetcherRef.current(urlRef.current, {
          arg: fetchData,
        })
        setData(fetchResult)

        setIsMutating(false)
        innerMutating.current = false

        if (onSuccess) {
          onSuccess(fetchResult)
        }
      } catch (err: any) {
        setIsMutating(false)
        innerMutating.current = false

        if (onError) {
          onError(err)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return {
    isMutating,
    trigger,
    data,
  }
}

export const useAlpcFetch = <TData>(
  options: FetchOptions,
  swrOptions?: UseAlpcFetchOptionsSwrOptions<TData, Error>
) => {
  const [retry, setRetry] = useState(false)
  const { isLoading: isProfileLoading, removeProfile } = useProfile()

  const { enable, ...otherSwrOptions } = swrOptions || {}

  const fetchEnable = (enable ?? true) && !isProfileLoading

  const { locale = '' } = useRouter()

  const { queryKey = [], body: fetchBody = {}, ...fetchOptions } = options

  const [url, requestBody] = queryKey

  const context = useSWR<TData>(
    [url, requestBody],
    !fetchEnable
      ? null
      : ([requestUrl, data]: [string, Record<string, any>]) =>
          fetcher({
            url: requestUrl,
            locale,
            fetchOptions: {
              method: 'POST',
              body: JSON.stringify({
                ...fetchBody,
                ...data,
              }),
              ...fetchOptions,
            },
            onReAuth: async () => {
              const result = await reAuth(locale, retry)

              if (result) {
                setRetry(true)
                return true
              }

              return false
            },
            onUnAuth() {
              // 失败后退出登录
              removeProfile()
            },
          }),
    {
      revalidateOnFocus: false,
      ...otherSwrOptions,
    }
  )

  return context
}

export const useAlpcMutation = <TData, TBody>(
  options: MutationOptions,
  mutationOptions?: UseMutationConfig<TData>
) => {
  const [retry, setRetry] = useState(false)
  const { removeProfile } = useProfile()

  const { locale = '' } = useRouter()

  const { url, initData, ...fetchOptions } = options

  const context = useMutation<TData, TBody>(
    url,
    (requestUrl, data: { arg: TBody }) =>
      fetcher({
        url: requestUrl,
        locale,
        fetchOptions: {
          method: 'POST',
          ...fetchOptions,
          body: JSON.stringify({
            ...initData,
            ...data.arg,
          }),
        },
        onReAuth: async () => {
          const result = await reAuth(locale, retry)

          if (result) {
            setRetry(true)
            return true
          }

          return false
        },
        onUnAuth() {
          // 失败后退出登录
          removeProfile()
        },
      }),
    mutationOptions
  )

  return context
}
