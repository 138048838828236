import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useContext,
  useState,
} from 'react'
import { Profile } from './types'
import fetcher from '@lib/fetcher'
import { useRouter } from 'next/router'
import { I18N_STORE_DOMAIN } from '@shopify/const'
import { CreditInfoResponse } from './response'
import { getMultipassToken } from '@shopify/utils'
import Cookies from 'js-cookie'
import useCreditInfo from './hooks/useCreditInfo'

interface ProfileContextValue {
  isLoading: boolean
  isCreditInfoLoading: boolean
  profile?: Profile
  creditInfo?: CreditInfoResponse
  fetchCreditInfo: () => Promise<void>
  fetchProfile: () => Promise<void>
  removeProfile: () => void
}

const contextInitialValue: ProfileContextValue = {
  fetchProfile: async () => {},
  fetchCreditInfo: async () => {},
  removeProfile: () => {},
  isLoading: false,
  isCreditInfoLoading: false,
}

export const ProfileContext =
  createContext<ProfileContextValue>(contextInitialValue)

export function ProfileProvider({ children }: PropsWithChildren<{}>) {
  const { locale } = useRouter()

  const [isLoading, setIsLoading] = useState(false)
  const [profile, setProfile] = useState<Profile>()

  const {
    creditInfo,
    isLoading: isCreditInfoLoading,
    getCreditInfo,
  } = useCreditInfo()

  const removeProfile = useCallback(() => {
    setIsLoading(true)
    setProfile(undefined)

    const storeDomain = I18N_STORE_DOMAIN[locale || '']
    const app = storeDomain.replace('.myshopify.com', '')

    Cookies.set(`${app}-info`, '', {
      Domain: '.eufy.com',
      expires: new Date('1970-01-01'),
    })
    Cookies.set('multipassinfo', '', {
      Domain: '.eufy.com',
      expires: new Date('1970-01-01'),
    })
    setTimeout(() => {
      setIsLoading(false)
    })
  }, [locale])

  const fetchProfile = useCallback(async () => {
    setIsLoading(true)

    const multipassinfo = getMultipassToken()

    if (!multipassinfo) {
      removeProfile()
      return
    }

    const response = await fetcher({
      method: 'GET',
      url: '/api/multipass/account/users/profile',
      body: {},
      locale,
      headers: {},
      repeatKey: '',
      type: '',
    })

    if (response?.status === 200) {
      const profileData = response.data?.data
      setProfile(profileData)
    } else {
      setProfile(undefined)
    }
    setIsLoading(false)
  }, [locale, removeProfile])

  const fetchCreditInfo = useCallback(() => {
    return getCreditInfo(profile?.user_id || '')
  }, [getCreditInfo, profile])

  useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  useEffect(() => {
    if (!isLoading && profile) {
      fetchCreditInfo()
    }
  }, [fetchCreditInfo, isLoading, profile])

  return (
    <ProfileContext.Provider
      value={{
        isLoading,
        isCreditInfoLoading,
        profile,
        fetchProfile,
        removeProfile,
        creditInfo,
        fetchCreditInfo,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

export const useProfile = () => useContext(ProfileContext)
