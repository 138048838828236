// start_ai_generated
import { getComponentData } from '@components/helper'
/* import Banner from './Banner'
import RecommendCard from './RecommendCard'
import Category from './Category'
import ImgWithTxt from './ImgWithTxt'
import VideoBanner from './VideoBanner'
import BlogList from './BlogList'
import Milestones from './Milestones'
import Media from './Media'
import Subscribe from './Subscribe'
import PopularProduct from './PopularProduct'
import FwEmbedFeed from '../../Common/FwEmbedFeed'
import Head from 'next/head'
import Paragraph from './Paragraph' */
import dynamic from 'next/dynamic'
import cn from 'classnames'
import s from './Home.module.css'
import useCustomer from '@shopify/customer/use-customer'
import { ProfileProvider } from 'templates/Pages/Credit/ProfileContext'
import { useEffect, useState } from 'react'
import Script from 'next/script'
import { ORIGIN_URL } from 'lib/config/const'

const PopularProduct = dynamic(() => import('./PopularProduct'))
const V4Banner = dynamic(() => import('./V4/Banner'))
const V4Recommend = dynamic(() => import('./V4/Recommend'))
const V4BestProducts = dynamic(() => import('./V4/BestProducts'))
const V4Category = dynamic(() => import('./V4/Category'))
const V4SystemBuild = dynamic(() => import('./V4/SystemBuild'))
const V4Member = dynamic(() => import('./V4/Member'))
const V4BestPrice = dynamic(() => import('./V4/BestPrice'))
const V4Benefits = dynamic(() => import('./V4/Benefits'))
const V4Serialization = dynamic(() => import('./V4/Serialization'))
const V4Invite = dynamic(() => import('./V4/Invite'))
const V4BrandVideo = dynamic(() => import('./V4/BrandVideo'))
const V4Safety = dynamic(() => import('./V4/Safety'))
const V4Slide = dynamic(() => import('./V4/Slide'))
const V4Support = dynamic(() => import('./V4/Support'))
const V4WhyChoose = dynamic(() => import('./V4/WhyChoose'))
const V4Media = dynamic(() => import('./V4/Media'))
const V4Reviews = dynamic(() => import('./V4/Reviews'))
// const V4Register = dynamic(() => import('./V4/Register'))
const V4SwiperBanner = dynamic(() => import('./V4/SwiperBanner'))
const V4ProductCard = dynamic(() => import('./V4/ProductCard'))
const V4Protecting = dynamic(() => import('./V4/Protecting'))
const v4SlideVideo = dynamic(() => import('./V4/SlideVideo'))
const v4CustomCard = dynamic(() => import('./V4/CustomCard'))
const V4StickyNav = dynamic(() => import('./V4/StickyNav'))

const Home = ({ metafields, relatedProducts, locale }) => {
  const { components, component_order, shopCommon, registrationsSettings } =
    metafields
  const { data: customer } = useCustomer()
  const [isMobile, setIsMobile] = useState(0)

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)
    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  const resizeWindow = () => {
    const offsetWidth =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth
    offsetWidth < 1024 ? setIsMobile(1) : setIsMobile(2)
  }

  /* useEffect(() => {
    if (
      isMobile === 1 &&
      location.pathname != '/homepage' &&
      location.pathname != '/' + locale + '/homepage'
    ) {
      location.href = '/' + locale + '/homepage'
    }
  }, [isMobile, locale]) */

  /* useEffect(() => {
    if (isMobile === 2 && !['eu-en', 'eu-es', 'us'].includes(locale)) {
      location.href = getThemeUrl('/', locale)
    }
  }, [isMobile, locale]) */

  return (
    <>
      <Script
        id="website-ld-schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "eufy",
      "url": "${ORIGIN_URL}${locale === 'us' ? '' : `/${locale}`}"
    }`,
        }}
      ></Script>
      {/* <div className="leading-[1.2] l:hidden">
        {locale == 'us' && <Head></Head>}
        {component_order &&
          component_order.map((dataKey) => {
            const { key: componentKey, data } = getComponentData(
              metafields,
              dataKey
            )
            const ComponentMap = {
              banner: Banner,
              recommendCards: RecommendCard,
              category: Category,
              exclusive: ImgWithTxt,
              videoBanner: VideoBanner,
              surport: ImgWithTxt,
              blogList: BlogList,
              milestones: Milestones,
              media: Media,
              // subscribe: Subscribe,
              popularProduct: PopularProduct,
              paragraph: Paragraph,
              fwEmbedFeed: FwEmbedFeed,
            }
            const Component = ComponentMap[componentKey]
            return Component ? (
              <Component
                key={dataKey}
                sectionKey={dataKey}
                section={data}
                locale={locale}
                shopCommon={shopCommon}
                relatedProducts={relatedProducts}
              />
            ) : null
          })}
      </div> */}
      <ProfileProvider>
        <div
          className={cn(
            'bg-[#f7f8f9] text-base leading-[1.4] text-primary',
            s.v4
          )}
        >
          {component_order.map((dataKey) => {
            const { key: componentKey, data } = getComponentData(
              metafields,
              dataKey
            )
            const ComponentMap = {
              v4Banner: V4Banner,
              v4Recommend: V4Recommend,
              v4BestProducts: V4BestProducts,
              v4Category: V4Category,
              v4SystemBuild: V4SystemBuild,
              v4Member: V4Member,
              v4BestPrice: V4BestPrice,
              v4Benefits: V4Benefits,
              v4Serialization: V4Serialization,
              v4Invite: V4Invite,
              v4BrandVideo: V4BrandVideo,
              v4Safety: V4Safety,
              v4Slide: V4Slide,
              v4Support: V4Support,
              v4WhyChoose: V4WhyChoose,
              v4Media: V4Media,
              v4Reviews: V4Reviews,
              // v4Register: V4Register,
              v4SwiperBanner: V4SwiperBanner,
              v4ProductCard: V4ProductCard,
              popularProduct: PopularProduct,
              v4Protecting: V4Protecting,
              v4SlideVideo: v4SlideVideo,
              v4CustomCard: v4CustomCard,
              v4StickyNav: V4StickyNav,
            }
            const Component = ComponentMap[componentKey]
            return Component && data ? (
              <Component
                key={dataKey}
                sectionKey={dataKey}
                section={data}
                locale={locale}
                shopCommon={shopCommon}
                relatedProducts={relatedProducts}
                registrationsMain={registrationsSettings}
                customer={customer}
              />
            ) : null
          })}
        </div>
      </ProfileProvider>
    </>
  )
}

export default Home
// end_ai_generated
